<template>
    <div class="d-flex overflow-hidden justify-content-center">
        <div
            class="cover-container position-relative w-100 gap-2 my-auto px-4 d-flex flex-column justify-content-center align-items-center d-none d-md-block">
            <div class="image-container position-relative">
                <img class="vvv-logo img-fluid" src="../../assets/VVV/images/Login/vvv-logo-transparent.png"
                    alt="VVV Logo" />
            </div>
        </div>
        <div class="position-relative w-100 gap-2 my-auto d-flex px-md-4 px-0 flex-column justify-content-center align-items-center"
            style="height: 100vh;">
            <div>
                <img class="d-flex m-auto" style="width: 45%;" src="../../assets/VVV/vvv-colored.png" alt="VVV Logo">
            </div>
            <div id="login-body" v-if="isSignIn">
                <div class="px-md-4 px-0">
                    <h3 class="fw-bold text-center my-4 mb-2 header" style="font-size: 20px;">Distributor Login</h3>
                    <p class="text-center mb-0"><strong>VeryViralVisuals</strong> is now <strong>VeryViralVideos</strong>!</p>
                    <p class="text-center">Login with your same credentials below.</p>
                    <div class="mt-4 mb-3">
                        <MDBInput class="my-3" label="Email" type="email" v-model="email" required
                            @keypress.enter="login" />
                        <MDBInput label="Password" type="password" v-model="password" required
                            @keypress.enter="login" />
                    </div>
                    <div class="text-end" style="font-size: 12px; cursor: pointer;" @click="toggleForgotPassword">
                        <span class="text-black header">Forgot Password?</span>
                    </div>
                    <button @click="login" data-mdb-ripple-init type="submit" color="primary"
                        class="btn btn-block btn-primary text-white mt-4">Log
                        in</button>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4">
                    <h6 class="m-0 me-1" style="font-size: 13px;">Don't have an account?</h6>
                    <a href="https://www.veryviralvisuals.com/esign" target="_blank" class="fw-bold text-black header"
                        style="font-size: 13px;">Sign
                        up</a>
                </div>
                <p class="text-center mt-4" style="font-size: 14px;">By continuing to use our service, you agree to
                    our <a href="https://www.veryviralvisuals.com/vvv-pp-tos" target="_blank">Terms of
                        Service</a>
                    which may be periodically updated.</p>
            </div>
            <div v-if="isForgotPassword" class="px-5">
                <div v-if="isSent">
                    <h3 class="text-center fw-bold my-4 mb-2" style="font-size: 24px;">Email has been sent.</h3>
                    <h6 class="text-center text-black-50 mt-3" style="margin-bottom: 30px;">Please follow the steps
                        mentioned in the email to reset your
                        password.</h6>
                </div>
                <div v-else>
                    <h3 class="text-center fw-bold my-4 mb-2 header" style="font-size: 24px;">Forgot Password</h3>
                    <h6 class="text-center text-black-50 mt-3" style="margin-bottom: 40px;">We'll email you a link to
                        reset your password.</h6>
                    <div>
                        <MDBInput class="my-3" label="Email" type="email" v-model="emailForget"
                            @keypress.enter="forgotPassword" required />
                    </div>
                    <button @click="forgotPassword" data-mdb-ripple-init type="submit"
                        class="btn btn-block btn-primary text-white mt-4">Send</button>
                </div>
                <div class="d-flex align-items-center justify-content-center mt-4">
                    <h6 class="m-0 me-1" style="font-size: 13px;">Back to</h6>
                    <span @click="toggleSignIn" target="_blank" class="fw-bold text-black"
                        style="cursor: pointer;font-size: 13px;">Log in</span>
                </div>
            </div>
        </div>
    </div>
    <MDBToast v-model="toastError" id="basic-danger-example" autohide position="top-right" appendToBody stacking
        width="350px" color="danger" text="white" icon="fas fa-info-circle fa-lg me-2">
        <template #title>Error</template>
        {{ toastMessage }}
    </MDBToast>
</template>

<script setup>
import { MDBInput, MDBToast } from 'mdb-vue-ui-kit';
import { ref, onMounted } from "vue";
import { useTitle } from "@vueuse/core";
import { useRouter } from "vue-router";
import axios from "axios";
import { isVVV } from "@/helpers/siteIdentifier";

useTitle("Login | Very Viral Videos");

const router = useRouter();

onMounted(() => {
    useTitle("Login | Very Viral Videos");
});

const email = ref("");
const password = ref("");
const toastError = ref(false);
const toastMessage = ref("");

const currentDashboard = isVVV() ? 3 : 2;

const login = () => {
    axios
        .post("api/users/login", {
            email: email.value,
            password: password.value,
            currentDashboard,
        })
        .then(function (response) {
            if (response.status === undefined) {
                if (response.response.status == 404) {
                    toastError.value = true;
                    toastMessage.value = "Incorrect email or password.";
                    password.value = "";
                } else {
                    toastError.value = true;
                    toastMessage.value = "An error occurred during request.";
                    password.value = "";
                }
            } else {
                router.push("/dashboard");
            }
        })
        .catch(function (error) {
            if (error.response.status == 404) {
                toastError.value = true;
                toastMessage.value = "Incorrect email or password.";
                password.value = "";
            } else {
                toastError.value = true;
                toastMessage.value = "An error occurred during request.";
                password.value = "";
            }
        });
};

const isSignIn = ref(true);
const isForgotPassword = ref(false);

const toggleForgotPassword = () => {
    isSent.value = false;
    isForgotPassword.value = true;
    isSignIn.value = false;
    useTitle("Forgot Password | Very Viral Videos");
};
const toggleSignIn = () => {
    isSignIn.value = true;
    isForgotPassword.value = false;
    useTitle("Login | Very Viral Videos");
};

const emailForget = ref("");
const isSent = ref(false);
const forgotPassword = () => {
    axios
        .post("api/users/forgotPassword", {
            email: emailForget.value,
            currentDashboard,
        })
        .then(function (response) {
            if (response.status === undefined) {
                if (response.response.status == 404) {
                    toastError.value = true;
                    toastMessage.value = "Email does not exist.";
                    emailForget.value = "";
                } else {
                    toastError.value = true;
                    toastMessage.value = "An error occurred during request.";
                    emailForget.value = "";
                }
            } else {
                isSent.value = true;
            }
        })
        .catch(function (error) {
            if (error.response.status == 404) {
                toastError.value = true;
                toastMessage.value = "Email does not exist.";
                emailForget.value = "";
            } else {
                toastError.value = true;
                toastMessage.value = "An error occurred during request.";
                emailForget.value = "";
            }
        });
};
</script>

<style>
:root {
  --white: #ffffff;
  --primary: #223698;
  --secondary: #e2e6ef;
  --accent: #02061b;
  --blue: #1f87fc;
  --mdb-body-font-size: 14px;
}

@font-face {
    font-family: 'GilroyRegular';
    src: url('../../assets/Gilroy-Font/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'GilroyBold';
    src: url('../../assets/Gilroy-Font/Gilroy-Bold.ttf');
}

* {
    font-family: 'GilroyRegular';
}

.header {
    font-family: 'GilroyBold';
}
</style>

<style lang="scss" scoped>
.cover-container {
    background-image: url("../../assets/VVV/images/Login/blue-gradient-bg-new.png");
    background-size: cover;
    width: 100%;
    height: 100vh;

    .vvv-logo {
        width: 250px;
        margin: 3%;
    }

    .image-container {
        width: 100%;
        height: 100%;
    }
}

.btn-primary {
    background-color: var(--primary);
    font-family: 'GilroyBold';
}

#login-body {
    width: 70%;
    max-width: 400px;
}
</style>
